import httpRequest from './httpService';
import _flatten from "lodash/flatten";

const getSegmentEmailList = async function(segment_id) {
    // get the list of email participants in this segment
    const response = await httpRequest.getParticipants(segment_id);
    const data = response.data.data.aggregations;
    const groupedData = Object.values([...data.sender_address.buckets, ...data.recipient_address.buckets, ...data.cc.buckets, ...data.bcc.buckets].reduce((acc, { key, doc_count }) => {
      acc[key] = { key, doc_count: (acc[key] ? acc[key].doc_count : 0) + doc_count  };
      return acc;
    }, {})).map(e => { return e.key });

    // remove any that are already on this avatar
    const aResponse = await httpRequest.getAvatars(segment_id);
    const avatars = aResponse.data.data.map(r => { return r.associated_info; });
    const existingEmails = _flatten(avatars);

    const emails = groupedData.filter(f => { return !existingEmails.includes(f); });
    return emails;
}

export default {
    getSegmentEmailList
};