<template>
  <v-app>
    <component :is="layout">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
      <div class="d-flex justify-center align-center" v-if="loading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
    </component>
    
    <v-snackbar v-model="showSnackbar" :color="snackbarColor">
      {{ snackbarText }}
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
const default_layout = "default";

export default {
  name: 'App',

  computed: {
    ...mapGetters({
      loading: "app/loadingData",
      snackbarColor: "app/snackbarColor",
      snackbarOpen: "app/snackbarOpen",
      snackbarText: "app/snackbarText"
    }),
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
    
    showSnackbar: {
      get() {
        return this.snackbarOpen
      },
      set() {
        this.closeMessage();
      }
    }
  },

  data: () => ({
    //
  }),

  methods: {
    ...mapActions({
      closeMessage: "app/closeMessage"
    }),
  }
};
</script>
