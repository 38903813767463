const state = {
  authentication: false,
  jwt: null,
  loadingData: false,  
  snackbar: {
    color: null,
    open: false,
    text: null
  },
  user: {}
};

const getters = {
  authenticated: (state) => state.authenticated,
  jwt: (state) => state.jwt,
  loadingData: (state) => state.loadingData,
  user: (state) => state.user,
  snackbarColor: (state) => state.snackbar.color,
  snackbarOpen: (state) => state.snackbar.open,
  snackbarText: (state) => state.snackbar.text
};

const mutations = {
  
  //SNACKBAR
  ADD_SNACKBAR_MESSAGE(state, msg) {
    state.snackbar.color = msg.color;
    state.snackbar.text = msg.text;
    state.snackbar.open = true;
  },

  CLOSE_SNACKBAR_MESSAGE(state) {
    state.snackbar.open = false;
    state.snackbar.color = null;
    state.snackbar.text = null;
  },

  SET_AUTHENTICATED(state, authenticated) {
    state.authenticated = authenticated;
  },
  SET_JWT(state, jwt) {
    state.jwt = jwt;
  },
  SET_LOADING_DATA(state, loading) {
    state.loadingData = loading;
  },
  SET_USER(state, user) {
    state.user = user;
  }
};

const actions = {  
  
  addSuccessMessage({ commit }, msg) {
    commit("ADD_SNACKBAR_MESSAGE", { color: "success", text: msg });
  },
  addErrorMessage({ commit }, msg) {
    commit("ADD_SNACKBAR_MESSAGE", { color: "error", text: msg });
  },
  closeMessage({ commit }) {
    commit("CLOSE_SNACKBAR_MESSAGE");
  },
  setAuthenticated({ commit }, authenticated) {
    commit("SET_AUTHENTICATED", authenticated);
  },
  setJwt({ commit }, jwt) {
    commit("SET_JWT", jwt);
  },
  setLoadingData({ commit }, loading) {
    commit("SET_LOADING_DATA", loading);
  },
  setUser({ commit }, user) {
    commit("SET_USER", user);
  }
};

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

export default module;
