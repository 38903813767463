<template>
  <div>
    <v-toolbar dense extension-height="36">
      <v-toolbar-title>{{formatName(data.id)}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <vue-json-to-csv :json-data="csvResults" :csv-title="csvTitle">
        <v-btn small color="secondary">
          <v-icon>mdi-download</v-icon> Download
        </v-btn>
      </vue-json-to-csv>
    </v-toolbar>
    <v-divider class="mb-10"></v-divider>

    <v-card class="mx-auto" max-width="750" v-if="!loading">
        <v-toolbar color="primary accent-4" dark>
          <v-icon class="mr-2">mdi-account-circle</v-icon>
          <v-toolbar-title>Avatars</v-toolbar-title>
        </v-toolbar>
        <v-text-field outlined dense label="Search" v-model="data.search" hide-details class="px-2 mt-2" autocomplete="off" v-on:keyup.enter="getSearchResults"></v-text-field>

        <v-data-table
          :headers="headers"
          :items="getAvatars"
          item-key="id">
          <template slot="item.avatar" slot-scope="props">
            <v-list-item-avatar>
              <v-img :alt="props.item.avatar_name" :src="props.item.avatar_image" v-if="props.item.avatar_image"></v-img>
              <v-avatar color="primary" v-else><span class="white--text">{{props.item.avatar_name.substring(0,1).toUpperCase()}}</span></v-avatar>
            </v-list-item-avatar>
          </template>
          <template slot="item.actions" slot-scope="props">
            <router-link :to="{name: 'avatar-detail', params: {avatar: props.item.id}}" style="text-decoration: none; color: inherit;">
              <v-icon>mdi-card-account-details</v-icon>
            </router-link>
          </template>
        </v-data-table>

        <v-divider></v-divider>
        <div class="text-right">
          <router-link :to="{name: 'avatar-detail', params: {avatar: 'new'}}" style="text-decoration: none; color: inherit;">
            <v-btn color="primary" class="ma-2">
              <v-icon>mdi-plus</v-icon> Add Avatar
            </v-btn>
          </router-link>
        </div>
        
      </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import httpRequest from "@/plugins/httpService";
import indexNaming from "@/plugins/indexNaming.js";
import VueJsonToCsv from 'vue-json-to-csv';

export default {
  name: 'AvatarList',
  components: {
    VueJsonToCsv
  },
  data: () => ({
    avatars: [],
    data: {
      search: ''
    },
    headers: [
      { text: '', value: 'avatar', sortable: false, width: '5%' },
      { text: 'Avatar', value: 'avatar_name', sortable: true, width: '90%' },
      { text: '', value: 'actions', sortable: false, width: '5%' }
    ]
  }),
  
  computed: {    
    ...mapGetters({
      loading: "app/loadingData"
    }),
    
    csvResults(){
      return this.getAvatars.map(r => { 
        return {
          id: r.id,
          avatar: r.avatar_name,
          type: r.avatar_type,
          image: r.avatar_image,
          associated_info: r.associated_info.join(", ").replaceAll(/"/g, "'")
        }
      })
    },
    
    csvTitle(){
      return this.formatName(this.data.id).split(' ').join('_') + '_Avatars';
    },

    getAvatars(){
      return this.avatars.filter(r => { 
        return r.avatar_name.concat('',r.associated_info).toLowerCase().includes(this.data.search.toLowerCase()); 
      });
    },

    getAvatarInitials(avatar_name){
      console.log(avatar_name);
      const nameArray = avatar_name.split(' ');
      return nameArray[0].substring(0,1) + nameArray[1].substring(0,1);
    }
  },

  methods: {

    ...mapActions({
      setLoadingData: "app/setLoadingData"
    }),

    formatName(index){
      return indexNaming.format(index);
    },

    async initData() {
      this.data.id = this.$route.params.id;
      this.setLoadingData(true);
      const response = await httpRequest.getAvatars(this.data.id);
      this.avatars = response.data.data;
      this.setLoadingData(false);
    },

    getSearchResults() {
      console.log('search');
    }
  },

  created() {
    this.initData();
  }
};
</script>
