import * as axios from "axios";
import store from '@/store';

const httpRequest = axios.create({ headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
  } 
});

httpRequest.interceptors.request.use(function (config) {
  const token = store.getters['app/jwt'];
  if (token){ config.headers = { 'X-Auth-Token': `${token}`}; }
  return config;
});

httpRequest.interceptors.response.use(
  res => res,
  err => {
    if (err && err.response && err.response.status === 401) {
      store.commit('SET_AUTHENTICATED', false);
      store.commit('SET_JWT', null);
      store.commit('SET_USER', {});
      window.location.replace(process.env.VUE_APP_HOME_URL);
    }
  }
);

const addAssociatedInfoToAvatar = async function(id, email){
  const response = await httpRequest.post(process.env.VUE_APP_SEARCH_API_URL + 'pb-avatars', {
    commandType: 'addAssociatedInfoToAvatar',
    avatarId: id,
    associatedInfo: email
  });
  return response;
}
  
const checkUserAccess = async function(){
  const response = await httpRequest.post(process.env.VUE_APP_SEARCH_API_URL + 'user-portal-api', {
    commandType: 'userApplications'
  });
  const appGuid = process.env.VUE_APP_ACCESS_GUID;
  if (response && response.data && response.data.data.length > 0){
    return (response.data.data.filter(d => { return d.applicationguid == appGuid }).length > 0);
  }
  return false;
}

const getAvatarById = async function(id){
  const response = await httpRequest.post(process.env.VUE_APP_SEARCH_API_URL + 'pb-avatars', {
    commandType: 'getAvatarById',
    id: id
  });
  return response;
}
  
const getAvatars = async function(index){
  const response = await httpRequest.post(process.env.VUE_APP_SEARCH_API_URL + 'pb-avatars', {
    commandType: 'getAvatars',
    index: index
  });
  return response;
}
  
const getDataSets = async function(){
  const response = await httpRequest.post(process.env.VUE_APP_SEARCH_API_URL + 'pb-search', {
    commandType: 'GetIndices'
  });
  return response;
}
  
const getParticipants = async function(index){

  let postBody = {
    commandType: 'GetParticipants',
    index: index
  };

  const response = await httpRequest.post(process.env.VUE_APP_SEARCH_API_URL + 'pb-search', postBody);
  return response;
}
  
const getUser = async function(){
  
  let postBody = {
    commandType: ''
  };

  const response = await httpRequest.post(process.env.VUE_APP_SEARCH_API_URL + 'user-portal-api', postBody);
  return response;
}

const removeAssociatedInfoFromAvatar = async function(id, email){
  const response = await httpRequest.post(process.env.VUE_APP_SEARCH_API_URL + 'pb-avatars', {
    commandType: 'removeAssociatedInfoFromAvatar',
    avatarId: id,
    associatedInfo: email
  });
  return response;
}
  
const saveAvatar = async function(segment, avatar, command){

  let postBody = {
    commandType: command,
    segment: segment,
    avatar: avatar
  };

  console.log(postBody);

  const response = await httpRequest.post(process.env.VUE_APP_SEARCH_API_URL + 'pb-avatars', postBody);
  return response;
}

export default {
  addAssociatedInfoToAvatar,
  checkUserAccess,
  getAvatarById,
  getAvatars,
  getDataSets,
  getParticipants,
  getUser,
  removeAssociatedInfoFromAvatar,
  saveAvatar
};