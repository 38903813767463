<template>
  <div>
    <v-toolbar dense extension-height="36">
      <v-toolbar-title>{{formatName(data.id)}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider class="mb-10"></v-divider>
 
    <v-row no-gutters justify="center" v-if="!loading">
      <v-col cols="12" md="5" class="pa-5">
        <v-card class="mx-auto">
          <v-toolbar color="primary accent-4" dark>
            <v-icon class="mr-2">mdi-account-circle</v-icon>
            <v-toolbar-title>{{data.avatar.avatar_name}}</v-toolbar-title>
          </v-toolbar>
          <v-row no-gutters>
            <v-col cols="6" md="4" class="pa-5">
              <v-img :src="data.avatar.avatar_image" v-if="data.avatar.avatar_image" />
              <v-avatar size="210" tile color="primary" v-else><span class="text-h1 white--text">{{(data.avatar.avatar_name || '').substring(0,1).toUpperCase()}}</span></v-avatar>
            </v-col>
            <v-col cols="12" sm="6" md="8" class="pa-5">
              <v-form id="avatar-form" ref="form" v-model="validForm">
                <v-row wrap>
                  <v-col cols="12">
                    <v-text-field outlined v-model="data.avatar.avatar_name" label="Name" required :rules="[v => !!v || 'Name is required']"></v-text-field>
                  </v-col>
                  
                  <v-col cols="12">
                    <v-text-field outlined v-model="data.avatar.avatar_image" label="Image"></v-text-field>
                  </v-col>
                      
                </v-row>
              </v-form>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <div class="d-flex justify-end">
            <v-btn class="ma-2 mr-0" :to="{name: 'avatar-list', params: {id: data.id}}">
              <v-icon>mdi-close</v-icon> Cancel
            </v-btn>
            <v-btn color="secondary" class="ma-2" :disabled="!validForm" @click="saveAvatar">
              <v-icon>mdi-content-save</v-icon> Save Avatar
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="5" class="pa-5">
        <v-card class="mx-auto">
          <v-toolbar color="primary accent-4" dark>
            <v-icon class="mr-2">mdi-email</v-icon>
            <v-toolbar-title>Emails</v-toolbar-title>
          </v-toolbar>
          
          <div class="d-flex">
            <v-autocomplete
              v-model="query"
              :items="emails"
              :loading="isLoading"
              chips
              deletable-chips
              hide-details
              hide-selected
              item-text="Name"
              item-value="ID"
              label="Search..."
              multiple
              return-object
              small-chips
              solo>
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    Search for an email...
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-text="item"></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
            <v-btn icon outlined color="secondary" class="ma-2" :disabled="!query || query.length == 0" @click="addAvatars">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </div>
          <v-divider></v-divider>
            
          <v-data-table
            class="mt-2"
            :hide-default-header="true"
            :headers="headers"
            :items="data.avatar.associated_info">
            <template slot="item.avatar" slot-scope="props">
              {{props.item}}
            </template>
            <template slot="item.actions" slot-scope="props">
              <v-btn small icon @click="removeEmail(props.item,props.index)">
                <v-icon>mdi-close-thick</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import avatarService from "@/plugins/avatarService";
import httpRequest from "@/plugins/httpService";
import indexNaming from "@/plugins/indexNaming.js";
import { uuid } from 'vue-uuid';

export default {
  name: 'AvatarDetail',
  data: () => ({
    addMode: false,
    data: {
      avatar: {}
    },
    emails: [],
    headers: [
      { text: '', value: 'avatar', sortable: false, width: '95%' },
      { text: '', value: 'actions', sortable: false, width: '5%' }
    ],
    isLoading: false,
    query: null,
    search: null,
    validForm: false
  }),
  
  computed: {    
    ...mapGetters({
      loading: "app/loadingData"
    })
  },

  methods: {

    ...mapActions({
      addSuccessMessage: "app/addSuccessMessage",
      addErrorMessage: "app/addErrorMessage",
      setLoadingData: "app/setLoadingData"
    }),

    async addAvatars(){
      if (this.query){ 

        const newQ = this.query;
        this.query = null;
        
        // move email from general list to avatar emails
        for (const a of newQ){
          if (!this.addMode){
            await httpRequest.addAssociatedInfoToAvatar(this.$route.params.avatar, a);
          }
          
          this.data.avatar.associated_info = this.data.avatar.associated_info.concat(a);
          const genIndex = this.emails.indexOf(a);
          this.emails.splice(genIndex, 1);
        }

        if (!this.addMode){
          this.addSuccessMessage('Successfully added email(s) to avatar!');
        }
        
        this.query = null;
        
      }
    },

    formatName(index){
      return indexNaming.format(index);
    },

    async initData() {
      this.data.id = this.$route.params.id;
      this.setLoadingData(true);

      if (this.$route.params.avatar != 'new'){
        const response = await httpRequest.getAvatarById(this.$route.params.avatar);
        this.data.avatar = response.data.data;
      } else {
        this.addMode = true;
        this.data.avatar.associated_info = [];
      }
      
      this.emails = await avatarService.getSegmentEmailList(this.data.id);
      this.setLoadingData(false);
    },

    async removeEmail(email, index){
      console.log('removing email ' + email);
      // move email from avatar email to general list

      if (!this.addMode){
        const response = await httpRequest.removeAssociatedInfoFromAvatar(this.$route.params.avatar, email);
        console.log(response);
        this.addSuccessMessage('Successfully removed email to avatar!');
      }

      this.data.avatar.associated_info.splice(index, 1);
      this.emails.push(email);
    },

    async saveAvatar() {
      console.log('save avatar!');
      let command = 'updateAvatar';

      if (this.addMode){
        this.data.avatar.id = uuid.v4();
        command = 'addAvatar';
      }

      const response = await httpRequest.saveAvatar(this.data.id, this.data.avatar, command);
      console.log(response);
      this.addSuccessMessage('Successfully saved avatar!');
      this.$router.push({ name: 'avatar-list', params: { id: this.data.id} });
    }
  },

  created() {
    this.initData();
  }

};
</script>
