import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from "@/auth/authGuard";

import Login from '@/Login.vue';
import Dashboard from '@/views/Dashboard.vue';
import AvatarDetail from '@/views/AvatarDetail.vue';
import AvatarList from '@/views/AvatarList.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Login
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
      path: '/dashboard',
      name: 'dashboard',
      beforeEnter: authGuard,
      component: Dashboard
  },
  {
      path: '/data/:id',
      name: 'avatar-list',
      beforeEnter: authGuard,
      component: AvatarList
  },
  {
      path: '/data/:id/detail/:avatar',
      name: 'avatar-detail',
      beforeEnter: authGuard,
      component: AvatarDetail
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
